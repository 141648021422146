// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//let url = `https://testapieli.bluedevs.com:3001/`
//let url = `http://localhost:3000/`
let url = `https://apieli.pulse.com.gt:3000/`
export const environment = {
  production: false,

  pulseURL: 'https://pulse.com.gt',

  idCorpLicense: `corp_license`,
  tokenID: `session_token`,
  idSubsidiary: `session_subsidiary`,
  userdata: `session_user`,
  admin: `session_admin`,
  licenseData: `session_license`,
  idData: `session_id`, //id of the logged license
  idUserData: `session_id_user`,
  subsidiaryData: `session_subsidiary`,
  usernameData: `session_username`,
  companyName: `session_companyName`,
  subsidiaryName: `session_subsidiaryName`,
  expiresInID: `token_expires_in`,
  send_storage:`${url}storage`,
  delete_storage:`${url}delete/storage`,
  get_storage:`${url}api/uploads/`,

  urlCheckToken: `${url}api/user/check`,
  getDepartmentsByLicenseId: `${url}api/department/getDepartmentsByLicenseId`,

  //Administration
  //Licenses
  activeByIdLicense: `${url}api/license/activeByIdLicense`,
  licenseRegister: `${url}api/license/create`,
  getAllLicenses: `${url}api/license/getAllLicenses`,
  getLicenseById: `${url}api/license/getLicenseById`,
  getAllLicensesInfo: `${url}api/license/getAllInfoLicenses`,
  getAllCompaniesInfo: `${url}api/license/getAllCompaniesInfo`,
  getAllModulesAccess: `${url}api/license/getAllInfoLicenses`,
  getTableInfo: `${url}api/license/getTableInfo`,
  getInstitutionTypes: `${url}api/license/getInstitutionTypes`,
  getCurrencyTypes: `${url}api/license/getCurrencyTypes`,
  //Company
  getCurrency:`${url}api/company/getCurrency`,
  insertlogocompany:`${url}api/company/insertlogocompany`,
  getlogocompany:`${url}api/company/getlogocompany`,
  updatelogocompany:`${url}api/company/updateLogo`,
  deleteLogocompany:`${url}api/company/deleteLogocompany`,
  registerEmail:`${url}api/company/registerEmail`,
  getRegisteredEmails:`${url}api/company/getRegisteredEmails`,
  deleteEmail:`${url}api/company/deleteEmail`,
  //Exchange Rate
  getExchangeRate: `${url}api/license/getExchangeRate`,
  createExchangeRate: `${url}api/license/createExchangeRate`,
  updateExchangeRate: `${url}api/license/updateExchangeRate`,
  deleteExchangeRate: `${url}api/license/deleteExchangeRate`,

  deleteById: `${url}api/license/deleteById`,
  getCorporativeLicenses: `${url}api/license/getCorporativeLicenses`,
  getLicenseFullInfoById: `${url}api/license/getLicenseFullInfoById`,
  getSublicenses: `${url}api/license/getSublicenses`,
  getLicenseIdByLicense: `${url}api/license/getLicenseIdByLicense`,
  UpdateLicenseById: `${url}api/license/UpdateById`,
  getModulesFromGroupId: `${url}api/license/getModulesFromGroupId`,
  getModulesById: `${url}api/license/getModulesById`,
  getModulesByLicenseId: `${url}api/license/getModulesByLicenseId`,
  //Users
  createUser: `${url}api/user/create`,
  userLogin: `${url}api/user/login`,
  getAllUsers: `${url}api/user/getAllUsers`,
  getUsersTable: `${url}api/user/getUsersTable`,
  getRoles: `${url}api/user/getRoles`,
  getUserById: `${url}api/user/getUserById`,
  UpdateById: `${url}api/user/UpdateById`,
  getUserIdByCredentials: `${url}api/user/getUserIdByCredentials`,
  getLicensesFromCredentials: `${url}api/user/getLicensesFromCredentials`,
  getUserByCorrelative: `${url}api/user/getUserByCorrelative`,
  getUserInfoById: `${url}api/user/getUserInfoById`,
  getLicenseInfoById: `${url}api/license/getLicenseInfoById`,
  DeleteByCorrelative: `${url}api/user/DeleteByCorrelative`,
  activateByCorrelative: `${url}api/user/activateByCorrelative`,
  getAdminPrivilege: `${url}api/user/getAdminPrivilege`,
  getIndividualLicenseByUser: `${url}api/user/getIndividualLicenseByUser`,
  //Modules
  getModules: `${url}api/modules/getModules`,

  //Permits
  getUsersForPermitsAdmin: `${url}api/user/getUsersForPermitsAdmin`,
  getPermitsByUser: `${url}api/permitStatus/getPermitsByUser`,
  getLicencesByUser: `${url}api/permitStatus/getLicensesByUser`,
  getPermitByName: `${url}api/permit/getPermitByName`,
  getGroupModules: `${url}api/permit/getGroupModules`,
  updatePermits: `${url}api/permitStatus/updatePermits`,

  //Subsidiaries
  getAvailableLicenses: `${url}api/subsidiary/getAvailableLicenses`,
  createSubsidiary: `${url}api/subsidiary/create`,
  getActualSubsidiaries: `${url}api/subsidiary/getActualSubsidiaries`,
  removeSubsidiaryById: `${url}api/subsidiary/removeSubsidiaryById`,
  UpdateSubsidiaryById: `${url}api/subsidiary/UpdateById`,
  getActualSubsidiary: `${url}api/subsidiary/getActualSubsidiary`,
  getSubsidiariesFromLicense: `${url}api/license/getSubsidiariesFromLicense`,
  getSubsidiaries: `${url}api/subsidiary/getSubsidiaries`,

  //Patients
  getCountries: `${url}api/patient/getCountries`,
  getDepartments: `${url}api/patient/getDepartments`,
  getTownships: `${url}api/patient/getTownships`,
  getTownshipsFromDepartment: `${url}api/patient/getTownshipsFromDepartment`,
  getCivilStatus: `${url}api/patient/getCivilStatus`,
  getReligions: `${url}api/patient/getReligions`,
  getEthnicities: `${url}api/patient/getEthnicities`,
  getOcupations: `${url}api/patient/getOcupations`,
  createPatient: `${url}api/patient/create`,
  getAllPatients: `${url}api/patient/getAllPatients`,
  getPatientsByParameters: `${url}api/patient/getPatientsByParameters`,
  removePatient: `${url}api/patient/removePatient`,
  getPatientByIdOnly: `${url}api/patient/getPatientByIdOnly`,
  getPatientById: `${url}api/patient/getPatientById`,
  updatePatient: `${url}api/patient/updatePatient`,
  getPatientsBySearch: `${url}api/patient/getPatientsBySearch`,
  getCompletePatientById: `${url}api/patient/getCompletePatientById`,
  getFilesById: `${url}api/patient/getFilesById`,
  //Admissions
  getAllAdmissionTypes: `${url}api/admissions/getAllAdmissionTypes`,
  admissionsGetAllPatients: `${url}api/admissions/getAllPatients`,
  admissionsGetAllDoctors: `${url}api/admissions/getAllDoctors`,
  admissionsGetAllDiseases: `${url}api/admissions/getAllDiseases`,
  getParenthood: `${url}api/admissions/getParenthood`,
  getDoctorsBySearch: `${url}api/admissions/getDoctorsBySearch`,
  getDiseasesBySearch: `${url}api/admissions/getDiseasesBySearch`,
  createAdmission: `${url}api/admissions/create`,
  getAdmissionsBySearch: `${url}api/admissions/getAdmissionsBySearch`,
  getActiveAdmissionsByPatientId: `${url}api/admissions/getActiveAdmissionsByPatientId`,
  getCountActiveAdmissionsByPatientId: `${url}api/admissions/getCountActiveAdmissionsByPatientId`,
  getCompleteAdmissionsBySearch: `${url}api/admissions/getCompleteAdmissionsBySearch`,
  dischargeAdmission: `${url}api/admissions/dischargeAdmission`,
  getAdmissionIdByIdCode: `${url}api/admissions/getAdmissionIdByIdCode`,
  getResponsibleByAdmissionId: `${url}api/admissions/getResponsibleByAdmissionId`,
  updateResponsible: `${url}api/admissions/updateResponsible`,
  getAdmissionInfoById: `${url}api/admissions/getAdmissionInfoById`,
  getDiseasesByAdmissionId: `${url}api/admissions/getDiseasesByAdmissionId`,
  getLogsBySearch: `${url}api/admissions/getLogsBySearch`,
  getAdmissionFilesById: `${url}api/admissions/getFilesById`,
  getIDAdmissionsBySearch: `${url}api/admissions/getIDAdmissionsBySearch`,
  getReactivatingLog: `${url}api/admissions/getReactivatingLog`,
  getDischargesLog: `${url}api/admissions/getDischargesLog`,
  updateAdmission: `${url}api/admissions/updateAdmission`,
  getPatientLogByIdAdmission: `${url}api/admissions/getPatientLogByIdAdmission`,
  generateBill: `${url}api/admissions/generateBill`,
  getBillTypes: `${url}api/admissions/getBillTypes`,
  getBillsBySearch: `${url}api/admissions/getBillsBySearch`,
  anulateBill: `${url}api/admissions/anulateBill`,
  updatePatientLogs: `${url}api/admissions/updatePatientLogs`,
  getAdmissions: `${url}api/admissions/getAdmissions`,

  //Reset Password
  sendResetEmail: `${url}api/passwordReset/sendResetEmail`,
  resetPassword: `${url}api/passwordReset/resetPassword`,
  verifyToken: `${url}api/passwordReset/verifyToken`,
  verifyUserInLicense: `${url}api/passwordReset/verifyUserInLicense`,

  //Inventories
  getBillingCategoryClassification: `${url}api/billingCategory/getBillingCategoryClasification`,
  getBillingCategories: `${url}api/billingCategory/getBillingCategories`,
  saveBillingCategory: `${url}api/billingCategory/createBillingCategory`,
  getBillingCategory: `${url}api/billingCategory/getBillingCategory`,
  updateBillingCategory: `${url}api/billingCategory/updateBillingCategory`,
  activate: `${url}api/billingCategory/activate`,
  deactivate: `${url}api/billingCategory/deactivate`,

  //Departments
  departments: `${url}api/department/getDepartments`,
  department: `${url}api/department/getDepartment`,
  createDepartment: `${url}api/department/createDepartment`,
  maxId: `${url}api/department/getMaxId`,
  getDepartmentUnitType: `${url}api/department/getDepartmentUnitType`,
  updateDepartment: `${url}api/department/updateDepartment`,
  departmentSubsidiaries: `${url}api/department/getDepartmentSubsidiaries`,
  getAssignedSubsidiariesDepartment: `${url}api/department/getAssignedSubsidiaries`,
  getSubsidiariesAvailableDepartment: `${url}api/department/getSubsidiariesAvailable`,
  getDepartamentSubDepartment: `${url}api/department/getDepartamentSubDepartment`,
  getSubsidiariesByIdDepartment: `${url}api/department/getSubsidiariesByIdDepartment`,

  //Sub-Departments
  createSubDepartment: `${url}api/subdepartment/createSubDepartment`,
  subDepartments: `${url}api/subdepartment/getSubDepartments`,
  subDepartment: `${url}api/subdepartment/getSubDepartment`,
  maxCode: `${url}api/subdepartment/getMaxCode`,
  updateSubDepartment: `${url}api/subdepartment/updateSubDepartment`,
  subDepartmentSubsidiaries: `${url}api/subdepartment/getDepartmentSubsidiaries`,
  activateSubDepartment: `${url}api/subdepartment/activate`,
  deactivateSubDepartment: `${url}api/subdepartment/deactivate`,
  //Products
  createProduct: `${url}api/product/createProduct`,
  billingCategories: `${url}api/product/billingCategories`,
  billingCategoriesPriceScale: `${url}api/product/billingCategoriesPriceScale`,
  purchaseCategory: `${url}api/product/purchaseCategory`,
  typeInventory: `${url}api/product/typeInventory`,
  typeCharge: `${url}api/product/typeCharge`,
  costType: `${url}api/product/costType`,
  inventoryMarkdown: `${url}api/product/inventoryMarkdown`,
  products: `${url}api/product/products`,
  getProductsScaleProduct: `${url}api/product/getProductsScaleProduct`, 
  productsTable: `${url}api/product/productsTable`,
  activateProduct: `${url}api/product/activateProduct`,
  deactivateProduct: `${url}api/product/deactivateProduct`,
  product: `${url}api/product/product`,
  maxIdProduct: `${url}api/product/maxId`,
  maxIdProducts: `${url}api/product/maxIdProduct`,
  updateProduct: `${url}api/product/updateProduct`,
  getProductManualPrice: `${url}api/product/getProductManualPrice`,
  massProductCreation: `${url}api/product/massProductCreation`,

  //Escala de Precios
  createRangeTable: `${url}api/princeScale/createRangeTable`,
  getCorrelativeRangeTable: `${url}api/princeScale/getCorrelativeRangeTable`,
  getCorrelativePriceScale:`${url}api/princeScale/getCorrelativePriceScale`,
  getCorrelativePriceScale_General:`${url}api/princeScale/getCorrelativePriceScale_General`,
  rangeTables: `${url}api/princeScale/rangeTables`,
  activeRangeTable: `${url}api/princeScale/activeRangeTable`,
  activatePriceRange: `${url}api/princeScale/activatePriceRange`,
  deactivatePriceRange: `${url}api/princeScale/deactivatePriceRange`,
  validateSubsidiaryPriceScale: `${url}api/princeScale/validateSubsidiaryPriceScale`,
  createPriceScale: `${url}api/princeScale/createPriceScale`,
  priceScale: `${url}api/princeScale/priceScale`,
  rangeTable: `${url}api/princeScale/rangeTable`,
  ranges: `${url}api/princeScale/ranges`,
  updateRangeTable: `${url}api/princeScale/updateRangeTable`,
  getPriceScale: `${url}api/princeScale/getPriceScale`,
  getPriceScaleRange: `${url}api/princeScale/getPriceScaleRange`,
  getGeneralPriceScaleRange: `${url}api/princeScale/getGeneralPriceScaleRange`,
  getProductsScalePrice: `${url}api/princeScale/getProductsScalePrice`,
  updatePriceScale: `${url}api/princeScale/updatePriceScale`,
  getUnassignedSubsidiaries: `${url}api/princeScale/getUnassignedSubsidiaries`,
  getAssignedSubsidiaries: `${url}api/princeScale/getAssignedSubsidiaries`,
  getAvailableSubsidiaries: `${url}api/princeScale/getAvailableSubsidiaries`,
  getSubsidiariesDisabled: `${url}api/princeScale/getSubsidiariesDisabled`,

  //Cargos
  catalogDepartment: `${url}api/department/getCatalogDepartment`,
  catalogSubDepartment: `${url}api/subdepartment/getCatalogSubDepartment`,
  getCollectionLog: `${url}api/charge/getCollectionLog`,
  createCollectionLog: `${url}api/charge/createCollectionLog`,
  activateCollectionLog: `${url}api/charge/activateCollectionLog`,
  deactivateCollectionLog: `${url}api/charge/deactivateCollectionLog`,
  getBasePriceScale: `${url}api/princeScale/getBasePriceScale`,
  getAllPriceScale: `${url}api/charge/getPriceScale`,
  getHistoryadmission:`${url}api/charge/getHistoryadmission`,
  createCharge: `${url}api/charge/createCharge`,
  cancellationOrderCharge:`${url}api/charge/cancellationOrderCharge`,
  transferCategory: `${url}api/charge/transferCategory`,
  updatestateChargeProduct:`${url}api/charge/updatestateChargeProduct`,
  updateDays:`${url}api/charge/updateDays`,
  createChargeProduct: `${url}api/charge/createChargeProduct`,
  createAssociatedCodes: `${url}api/charge/createAssociatedCodes`,
  updateAssociateCodes:`${url}api/charge/updateAssociateCodes`,
  getAssociateCodes:`${url}api/charge/getAssociateCodes`,
  getPriceInfoAssociateCodes:`${url}api/charge/getPriceInfoAssociateCodes`,
  getAllInfoAssociateCodes:`${url}api/charge/getAllInfoAssociateCodes`,
  getInfoAssociateCodes:`${url}api/charge/getInfoAssociateCodes`,
  getCorrelativeNumberOrder: `${url}api/charge/getCorrelativeNumberOrder`,
  getCorrelativeNumberDepartament: `${url}api/charge/getCorrelativeNumberDepartament`,
  getAliasDepartment: `${url}api/charge/getAliasDepartment`,
  getAliasSubDepartment: `${url}api/charge/getAliasSubDepartment`,
  getAutomaticCharges: `${url}api/charge/getAutomaticCharges`,
  getCharges: `${url}api/charge/getCharges`,
  getAutomaticChargesProducts: `${url}api/charge/getAutomaticChargesProducts`,
  getDetailsProduct:`${url}api/charge/getDetailsProduct`,
  getChargesProducts: `${url}api/charge/getChargesProducts`,
  getSubsidiaryNumber: `${url}api/charge/getSubsidiaryNumber`,
  getCatalogDepartmentById: `${url}api/department/getCatalogDepartmentById`,
  getproductsbyOrder:`${url}api/charge/getproductsbyOrder`,
  getproductsbyadmission:`${url}api/charge/getproductsbyadmission`,
  //Requerimientos de insumos
  crateRequirementDepartment:`${url}api/charge/crateRequirementDepartment`,
  createRequirementDepartmentProduct:`${url}api/charge/createRequirementDepartmentProduct`,
  createDispatch:`${url}api/charge/createDispatch`,
  updateRequirementDepartment:`${url}api/charge/updateRequirementDepartment`,
  getRequirementDepartment:`${url}api/charge/getRequirementDepartment`,
  getRequirementProduct:`${url}api/charge/getRequirementProduct`,


  // Habitaciones
  rooms: `${url}api/rooms/areas`,
  roomsDetail: `${url}api/rooms/areasDetail`,
  pauseRoomArea: `${url}api/rooms/pauseRoomArea`,
  // Catalogos
  subsidiaryCatalog: `${url}api/rooms/subsidiaryCatalog`,
  roomsMaintenanceCreateCatalog: `${url}api/rooms/roomsMaintenanceCreateCatalog`,
  roomsProductCodesCatalog: `${url}api/rooms/productCodesCatalog`,
  // Rooms Area Rooms
  roomsAreaRooms: `${url}api/rooms/roomsAreaRooms`,
  pauseRoomsAreaRoom: `${url}api/rooms/pauseRoomsAreaRoom`,
  // Rooms Assigment
  roomsAssigment: `${url}api/rooms/roomsAssigment`,
  roomsAssigmentBySubsidiary: `${url}api/rooms/roomsAssigmentBySubsidiary`,
  roomsSearchAssigment: `${url}api/rooms/roomsSearchAssigment`,
  roomsRecordsAssigment: `${url}api/rooms/roomsRecordsAssigment`,
  roomsAssigmentActualRoom: `${url}api/rooms/actualRoom`,
  roomsAssigmentRoomsCatalog: `${url}api/rooms/roomsAssigmentRoomsCatalog`,
  roomsAdmissionCatalog: `${url}api/rooms/roomsAssigmentAdmissionCatalog`,

  generateReport: `${url}api/reports/generate`
};
