import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  //Get users
  public getUsers(): Observable<any>{
    return this.http.get(environment.getUsersTable).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Get user by correlative
  public getUserByCorrelative(correlative:string, id_license, admin): Observable<any>{
    return this.http.post(environment.getUserByCorrelative, {"correlative" : correlative, id_license:id_license, admin:admin }).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  public getUsersTable(credentials: any): Observable<any>{
    return this.http.post(environment.getUsersTable, credentials).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  public getUserIdByCredentials(credentials: any): Observable<any>{
    return this.http.post(environment.getUserIdByCredentials, credentials).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Get users for admin permits
  public getUsersForPermitsAdmin(id_license_session, isAdmin): Observable<any>{
    let options =  new HttpParams();
    options = options.append('isAdmin', isAdmin);
    options = options.append('id_license_session', id_license_session);
    ////console.log(options);
    return this.http.get(environment.getUsersForPermitsAdmin,{params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  public getAdminPrivilege(id_user, id_license): Observable<any>{
    return this.http.post(environment.getAdminPrivilege, { id_user, id_license }).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  public getIndividualLicenseByUser(id_user, corpLicense, isAdmin): Observable<any>{
    let data = {id_user, corpLicense, isAdmin };
    return this.http.post(environment.getIndividualLicenseByUser, data ).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Get roles for the user
  public getAllCompaniesInfo(corp_license, isAdmin, licensesToIgnore?): Observable<any>{
    let options =  new HttpParams();
    options = options.append('corp_license', corp_license);
    options = options.append('isAdmin', isAdmin);

    if(licensesToIgnore){
      options = options.append('licensesToIgnore', licensesToIgnore);
    }
    return this.http.get(environment.getAllCompaniesInfo, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
}
