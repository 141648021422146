import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Response, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LicenseService } from './administration/license/license.service';
import { UsersService } from './administration/users/users.service';
import { forkJoin } from 'rxjs';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient, private router: Router, private location: Location, private userService:UsersService, private cookieService: CookieService, private licenseService: LicenseService) { }

  private corp_license;

  logOut(){
    this.cookieService.deleteAll();
    this.router.navigate(['/pages/login']);
  }

  public getLicensesFromCredentials(credentials): Observable<any>{
    return this._http.post(environment.getLicensesFromCredentials, credentials).pipe(
      map(res => {
        ////console.log("res", res);
        this.corp_license = res['corp_license'];
        return res;
      }),
      catchError(err => of(err))
    )
  }

  signUp(userData): Observable<any> {

    let params = { user: JSON.stringify(userData) };
    let expiredDate;
    ////console.log("Params", userData);
    return this._http.post(environment.userLogin, params).pipe(
      map(res => {
        
        forkJoin([this.userService.getUserIdByCredentials(params)]).subscribe(
          (res) => {
            ////console.log("Response", res);
            userData.id = userData.id_license;
            userData.id_user = res[0].id_user;
            userData.admin_privilege = res[0].admin_privilege;
            
            ////console.log("USERDATA: ",userData);

            this.cookieService.set(environment.companyName, String(userData.companyName), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.subsidiaryName, String(userData.subsidiaryName), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.usernameData, String(userData.user), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.idSubsidiary, String(userData.id_subsidiary), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.idData, String(userData.id), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.admin, String(userData.admin_privilege), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.idUserData, String(userData.id_user), expiredDate, '/', '', false, "Lax");
            this.cookieService.set(environment.idCorpLicense, String(this.corp_license), expiredDate, '/', '', false, "Lax");
            
          },
          (err) => {
            console.error(err);
          }
        )
        
        expiredDate = new Date(Date.now() + res['expires_in']);
        this.cookieService.set(environment.userdata, String(userData.user), expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.licenseData, String(userData.id_license), expiredDate, '/', '', false, "Lax");
        
        this.cookieService.set(environment.tokenID, res['token'], expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.expiresInID, res['expires_in'], expiredDate, '/', '', false, "Lax");
        return res;
      })
    );
  }


  requestToken() {
    let expiredDate;
    return this._http.get(environment.urlCheckToken).pipe(
      map(res => {
        // //console.log('resToken', res);
        expiredDate = new Date(Date.now() + res['expires_in']);
        // //console.log('Se ha actualizado el token');
        // //console.log(expiredDate);
        if(this.cookieService.get(environment.userdata))
          this.cookieService.set(environment.userdata, this.cookieService.get(environment.userdata), expiredDate, '/', '', false, "Lax");

        if(this.cookieService.get(environment.companyName))
          this.cookieService.set(environment.companyName, this.cookieService.get(environment.companyName), expiredDate, '/', '', false, "Lax");

        if(this.cookieService.get(environment.subsidiaryName))
          this.cookieService.set(environment.subsidiaryName, this.cookieService.get(environment.subsidiaryName), expiredDate, '/', '', false, "Lax");
        
        if(this.cookieService.get(environment.usernameData))
          this.cookieService.set(environment.usernameData, this.cookieService.get(environment.usernameData), expiredDate, '/', '', false, "Lax");
        
        if(this.cookieService.get(environment.idSubsidiary))
          this.cookieService.set(environment.idSubsidiary, this.cookieService.get(environment.idSubsidiary), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.idData))
          this.cookieService.set(environment.idData, this.cookieService.get(environment.idData), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.admin))
          this.cookieService.set(environment.admin, this.cookieService.get(environment.admin), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.idUserData))
          this.cookieService.set(environment.idUserData, this.cookieService.get(environment.idUserData), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.idCorpLicense))
          this.cookieService.set(environment.idCorpLicense, this.cookieService.get(environment.idCorpLicense), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.licenseData))
          this.cookieService.set(environment.licenseData, this.cookieService.get(environment.licenseData), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.subsidiaryData))
          this.cookieService.set(environment.subsidiaryData, this.cookieService.get(environment.subsidiaryData), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.tokenID))
          this.cookieService.set(environment.tokenID, this.cookieService.get(environment.tokenID), expiredDate, '/', '', false, "Lax");
          
        if(this.cookieService.get(environment.expiresInID))
          this.cookieService.set(environment.expiresInID, this.cookieService.get(environment.expiresInID), expiredDate, '/', '', false, "Lax");

        return true;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getToken() {
    return this.cookieService.get(environment.tokenID);
  }

  async verificarToken() {
    let t;
    try {
      t = await this.requestToken().toPromise();
    } catch (error) {
      console.error('E', error);
      t = false;
    }
    return t;
  }

  sesionValida() {
    return new Promise((resolve, reject) => {
      this.verificarToken().then((res) => {
        // //console.log("Token", res);
        if (this.cookieService.check(environment.tokenID) && res) {
          resolve(true);
        }
        else {
          resolve(false);
        }
      })
    })
  }
}

