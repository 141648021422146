import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { ExchangeRate } from '../../../models/Exchange_Rate';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(private cookieService: CookieService, private http: HttpClient) { }

    //Get users for admin permits
    public getCorporativeLicenses(): Observable<any>{
      return this.http.get(environment.getCorporativeLicenses).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    //Get users for admin permits
    public getModulesByLicenseId(license): Observable<any>{
      let options =  new HttpParams();
      options = options.append('license', license);
      return this.http.get(environment.getModulesByLicenseId, {params: options}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    public getLicenseById(id): Observable<any> {
      return this.http.post(environment.getLicenseById, {'id': id}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    public getSublicenses(id_upperlicense): Observable<any> {
      return this.http.post(environment.getSublicenses, {'id_upperLicense': id_upperlicense}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    public getModulesById(id_license): Observable<any> {
      return this.http.post(environment.getModulesById, {'id_license': id_license}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    public getSubsidiariesFromLicense(id): Observable<any> {
      
      return this.http.post(environment.getSubsidiariesFromLicense, {'id': id}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    public getModulesFromGroupId(id_module): Observable<any> {
      
      return this.http.post(environment.getModulesFromGroupId, {'id_module': id_module}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    //Get users for admin permits
    public getTableInfo(): Observable<any>{
      return this.http.get(environment.getTableInfo).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }


  //Get institution types
  public getInstitutionTypes(): Observable<any> {
    return this.http.get(environment.getInstitutionTypes).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }


  //Get base currency
  public getCurrencyTypes(): Observable<any>{
    return this.http.get(environment.getCurrencyTypes).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
  //Insert Logo of company
  public insertlogocompany(id_license:any,image:string,position:number){
    return this.http.post(environment.insertlogocompany,{"id_license":id_license, "image":image,"position":position});
  }
  //Get Logo of company
  public getlogocompany(id_license: any): Observable<any>{
    return this.http.post(environment.getlogocompany, {'id_license': id_license}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
  //Update Logo of company
  public updatelogocompany(id_license:any,image:string,position:number){
    return this.http.post(environment.updatelogocompany,{"id_license":id_license, "image":image,"position":position});
  }
  //Delete Logo of company
  public deletelogocompany(id_license:any,position:number){
    return this.http.post(environment.deleteLogocompany, {'id_license':id_license, "position":position});
  }

  //Register subsidiary email
  public registerEmail(id_subsidiary:any,email:string){
    return this.http.post(environment.registerEmail,{"id_subsidiary":id_subsidiary, "email":email}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Get subsidiary emails
  public getRegisteredEmails(id_subsidiary: any): Observable<any>{
    let options =  new HttpParams();
    options = options.append('subsidiary_id', id_subsidiary);
    return this.http.get(environment.getRegisteredEmails, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
  //Delete email
  public deleteEmail(id){
    return this.http.post(environment.deleteEmail,{"email_id":id}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
  
  //Get currency by company
  public getCurrency(id_license: any): Observable<any>{
    return this.http.post(environment.getCurrency, {'id_license': id_license}).pipe(
      map(res => {
        return res[0];
      }),
      catchError(err => of(err))
    )
  }

  //Get Exchange Rate
  public getExchangeRate(id): Observable<any>{
    return this.http.post(environment.getExchangeRate, {'id': id}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  public deleteExchangeRate(id){
    return this.http.post(environment.deleteExchangeRate, {'id': id});
  }

  public saveExchangeRate(exchangeRate:ExchangeRate){
    return this.http.post(environment.createExchangeRate, exchangeRate);
  }


  public updateExchangeRate(id: string | number, exchangeRate: ExchangeRate){
    return this.http.post(environment.updateExchangeRate, exchangeRate);
  }

    //Get available licenses
    public getAvailableLicenses(id_upperlicense, admin): Observable<any> {
      return this.http.post(environment.getAvailableLicenses, {'id_upperLicense': id_upperlicense, 'isAdmin': admin}).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    public getLicenseIdByLicense(userData): Observable<any> {
      return this.http.post(environment.getLicenseIdByLicense, userData).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }

    //Get full license info
    public getLicenseFullInfoById(id_license: string): Observable<any> {
      return this.http.post(environment.getLicenseFullInfoById, JSON.stringify({'id_license': id_license}), { 'headers': { "Content-Type": "application/json" } }).pipe(
        map(res => {
          return res;
        }),
        catchError(err => of(err))
      )
    }
  

    //Get companies information
    public getAllCompaniesInfo(corp_license, isAdmin): Observable<any> {
      if(corp_license){
        let options =  new HttpParams();
        options = options.append('corp_license', corp_license);
        options = options.append('isAdmin', isAdmin);
        return this.http.get(environment.getAllCompaniesInfo, {params: options}).pipe(
          map(res => {
            return res;
          }),
          catchError(err => of(err))
        )
      }else{
        return this.http.get(environment.getAllCompaniesInfo).pipe(
          map(res => {
            return res;
          }),
          catchError(err => of(err))
        )
      }
    }
}
