import { Location } from '@angular/common';
import { Routes } from '@angular/router';
import { AuthGuard } from '../app/services/guard/auth.guard'

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'pages/login',
        pathMatch: 'full',
    }, {
        path: '',
        canActivate: [AuthGuard],
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: './dashboard/dashboard.module#DashboardModule'
        }, {
            path: 'admin',
            loadChildren: './admin/admin.module#AdminModule'
        }, {
            path: 'unauthorized',
            loadChildren: './unathorized/unathorized.module#UnathorizedModule'
        }, {
            path: 'admissions',
            loadChildren: './admissions/admissions.module#AdmissionsModule'
        }, {
            path: 'loginSelection',
            loadChildren: './login-selection/login-selection.module#LoginSelectionModule'
        }, {
            path: 'inventories',
            loadChildren: './inventories/inventories.module#InventoriesModule'
        }, {
            path: 'charges',
            loadChildren: './charges/charges.module#ChargesModule'
        }, {
            path: 'patients',
            loadChildren: './patients/patients.module#PatientsModule'
        }, {
            path: 'components',
            loadChildren: './components/components.module#ComponentsModule'
        }, {
            path: 'forms',
            loadChildren: './forms/forms.module#Forms'
        }, {
            path: 'tables',
            loadChildren: './tables/tables.module#TablesModule'
        }, {
            path: 'maps',
            loadChildren: './maps/maps.module#MapsModule'
        }, {
            path: 'charts',
            loadChildren: './charts/charts.module#ChartsModule'
        }, {
            path: 'calendar',
            loadChildren: './calendar/calendar.module#CalendarModule'
        }, {
            path: '',
            loadChildren: './userpage/user.module#UserModule'
        }, {
            path: '',
            loadChildren: './timeline/timeline.module#TimelineModule'
        }, {
            path: '',
            loadChildren: './widgets/widgets.module#WidgetsModule'
        }, {
            path: 'rooms',
            loadChildren: './rooms/rooms.module#RoomsModule'
        }, {
            path: 'reports',
            loadChildren: './reports/reports.module#ReportsModule'
        }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
