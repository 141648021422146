import {Component} from "@angular/core";
import {ICellRendererParams} from "ag-grid-community";
import {AgRendererComponent} from "ag-grid-angular";
import { LicenseService } from '../../services/administration/license/license.service';
import { ExchangeRateComponent } from './exchange-rate.component';

@Component({
    selector: 'child-cell',
    template: `<span><button style="height: 20px" (click)="invokeParentMethod()" class="btn btn-danger btn-link btn-icon btn-sm remove"><li class="fa fa-times" style="font-size: 18px;"></li></button></span>`,
    styles: [
        `.btn {
            line-height: 0.5
        }`
    ]
})
export class ChildMessageRenderer implements AgRendererComponent {

    constructor(private licenseService:LicenseService, private exchangeRate :ExchangeRateComponent){}

    public params?: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
    
    public invokeParentMethod() {
        let id = this.params.data.IdExchangeRate;
        
        this.exchangeRate.DeleteData(id);

        //this.params?.context.componentParent.methodFromParent(`Col: ${this.params.colDef?.headerName}`)
    }

    refresh(): boolean {
        return false;
    }
}