import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authentication: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      return this.authentication.sesionValida().then(res => {
        if(res == true){
          ////console.log("respuesta auth", res)
          return true;
        }
        ////console.log("auth fallida", res)
        this.router.navigate(['pages/login'], { queryParams: { returnUrl: state.url } });
        // this.router.navigate(['pages/login'], {state: {data: {exit: true}, navigationId: 25}});
        return false;
      })
    // return true;
  }
  
}
