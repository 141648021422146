import {  Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermitsService } from 'app/services/administration/permits/permits.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin } from 'rxjs';
import { isConstructorDeclaration } from 'typescript';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    styleUrls: ['./sidebar.component.css'],
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];
    userPermits: any;
    userName: string;
    isNotMobileMenu(){
        if( window.outerWidth > 991){
            return false;
        }
        return true;
    }

    getDashboard(){
        let dashboard = {
            path: '/dashboard',
            title: 'Dashboard',
            type: 'link',
            icontype: '../../assets/img/icons/dashboard.svg'
        };
        return dashboard;
    }
    

    getAdmin(){
        let children = [];

        if(this.userPermits[0].some(p => !(p.create == 0 && p.edit == 0 && p.delete == 0 && p.pause == 0)))
            children.push({path: 'users', title: 'Usuarios', ab:'U'})

        if(this.userPermits[0].some(p => p.view_license == 1)){
            children.push({path: 'licenses', title: 'Licencias', ab:'L'})
        }
     
        children.push({path: 'exchange-rate', title: 'Tipo de Cambio', ab:'M'})
        
        
        if(this.userPermits[18].some(p => !(p.add == 0))){
            children.push({path:'logo-company', title: 'Logo',ab:'LC'})
        }
        // if(this.userPermits[0].some(p => !(p.create == 0))){
            children.push({path:'email', title: 'Correos Automáticos',ab:'E'})
        // }
        if(this.userPermits[0].some(p => p.edit_permits == 1)){
            children.push({path: 'permits', title: 'Permisos', ab:'P'},)
        }
        if(this.userPermits[0].some(p => p.view_subsidiary == 1)){
            children.push({path: 'subsidiary', title: 'Sucursales', ab:'S'})
        }

        return {
            path: '/admin',
            title: 'Administración',
            type: 'sub',
            collapse: 'admin',
            icontype: '../../assets/img/icons/administracion.svg',
            children: children
        }
    }

    getPatients(){
        let children = [];
        
        if(this.userPermits[1].some(p => p.search == 1))
        {
            children.push({path: 'patient-lookup', title: 'Buscar Paciente', ab:'BP'})
        }
        if(this.userPermits[1].some(p => p.create == 1))
        {
            children.push({path: 'patient-search', title: 'Crear Pacientes', ab:'P'})
        }
        
        return {
            path: '/patients',
            title: 'Pacientes',
            type: 'sub',
            collapse: 'patients',
            icontype: '../../assets/img/icons/paciente.svg',
            children: children
        }
    }

    getAdmissions(){
        let children = []

        try {
            if(this.userPermits[2]){

                if(this.userPermits[2].some(p => p.opening == 1))
                {
                    children.push({path: 'opening', title: 'Apertura de Admisiones', ab:'AA'})
                }

                if(this.userPermits[2].some(p => p.discharge == 1))
                {
                    children.push({path: 'discharge', title: 'Dar de Alta', ab:'AL'})
                }
        
                if(this.userPermits[2].some(p => p.reopen == 1))
                {
                    children.push({path: 'reopen', title: 'Reactivar Admisión', ab:'RA'})
                }
        
                if(this.userPermits[2].some(p => p.edit == 1))
                {
                    children.push({path: 'edit', title: 'Editar Admisión', ab:'EA'})
                }
        
                if(this.userPermits[2].some(p => p.create_bills == 1))
                {
                    children.push({path: 'bill', title: 'Admisiones Facturadas', ab:'AF'})
                }
        
                if(this.userPermits[2].some(p => p.view_logs == 1))
                {
                    children.push({path: 'logs', title: 'Bitácora', ab:'BI'})
                }
        
                if(this.userPermits[2].some(p => p.search == 1))
                {
                    children.push({path: 'search', title: 'Buscar Admisión', ab:'BA'})
                }

            }
        } catch (error) { }

        return {
            path: '/admissions',
            title: 'Admisiones',
            type: 'sub',
            collapse: 'admissions',
            icontype: '../../assets/img/icons/admisiones.svg',
            children: children
        }
    }


    getInventories(){
        let children = []
        if(this.userPermits[4].some(p => p.add == 1 ||p.view == 1 || p.edit == 1 || p.pause == 1)){
            children.push({path: 'billing-category', title: 'Categoría de Facturación', ab:'CF'})
        }
        if(this.userPermits[5].some(p => p.add_department == 1 || p.add_subdepartment == 1 || p.edit == 1 || p.visualize == 1 )){
            children.push({path: 'departments', title: 'Departamentos', ab:'DP'})
        }
        if(this.userPermits[6].some(p => p.drug_purchase_category == 1 || p.purchase_category_supplies == 1 || p.service_purchase_category == 1 ||
            p.view == 1 || p.edit == 1 || p.edit_special == 1 || p.pause == 1 )){

                children.push({path: 'products', title: 'Productos', ab:'PR'}),
                children.push({path: 'bulk-load-products', title: 'Carga masiva de Productos', ab:'BL'})
            }

        if(this.userPermits[7].some(p => p.range_table == 1 || p.edit_table == 1 || p.pause_table == 1)){
            children.push({path: 'price-range', title: 'Tabla de Rango', ab:'TR'})
        }
        if(this.userPermits[7].some(p => p.esc_price_base == 1 || p.esc_price_general == 1 || p.view_esc == 1 || p.edit_esc_base == 1 || p.edit_esc_general == 1)){
            children.push({path: 'price-scale', title: 'Escala De Precios', ab:'EP'})
        }

        return {
            path: '/inventories',
            title: 'Inventarios',
            type: 'sub', 
            collapse: 'inventories',
            icontype: '../../assets/img/icons/inventories.png',
            children: children
        }
    }


    getCharges(){
        let children = []
            if(this.userPermits[9].some(p => p.register == 1 || p.pause == 1)){
                children.push({path: 'collection-log-register', title: 'Bitácora de Cobro', ab:'BC'})
            }
            if(this.userPermits[10].some(p => p.generate_detail == 1)){
            children.push({path: 'details-charges', title: 'Detalle de Cargos', ab:'DC'})
            }
            if(this.userPermits[8].some(p => p.category_medicine == 1 || p.category_service == 1 || p.category_supply == 1 || p.type_order == 1 || p.view == 1 )){
                children.push({path: 'charges', title: 'Cargos', ab:'CA'})
            }
            if(this.userPermits[11].some(p =>p.category_change == 1 || p.chargeback == 1 ||  p.room_day == 1 || p.order_cancellation == 1 || p.cancellation_charge == 1 )){
                children.push({path: 'modify-charge', title: 'Modificar Cargos', ab:'MC'})
            }
            if(this.userPermits[12].some(p => p.create == 1 || p.view == 1 || p.edit == 1 || p.EXCEL == 1)){
                children.push({path: 'associated-codes', title: 'Códigos Asociados', ab:'CA'})
            }
            if((this.userPermits[13].some(p => p.cancel == 1 || p.dispatch == 1 || p.edit == 1 || p.view == 1)) || (this.userPermits[14].some(p => p.cancel == 1 || p.dispatch == 1 || p.edit == 1 || p.view == 1)) ||
            (this.userPermits[15].some(p => p.cancel == 1 || p.dispatch == 1 || p.edit == 1 || p.view == 1)) ||(this.userPermits[16].some(p => p.cancel == 1 || p.dispatch == 1 || p.edit == 1 || p.view == 1))  )
            children.push({path: 'requirements-supplies', title: 'Requerimiento de Insumos', ab:'RI'})
        
        return {
            path: '/charges',
            title: 'Cargos',
            type: 'sub', 
            collapse: 'charges',
            icontype: '../../assets/img/icons/charges.png',
            children: children
        }
    }


    getForms(){
        return {
            path: '/forms',
            title: 'Forms',
            type: 'sub',
            collapse: 'forms',
            icontype: 'nc-icon nc-ruler-pencil',
            children: [
                {path: 'regular', title: 'Regular Forms', ab:'RF'},
                {path: 'extended', title: 'Extended Forms', ab:'EF'},
                {path: 'validation', title: 'Validation Forms', ab:'VF'},
                {path: 'wizard', title: 'Wizard', ab:'W'}
            ]
        }
    }

    getComponents(){
        return {
            path: '/components',
            title: 'Components',
            type: 'sub',
            collapse: 'components',
            icontype: 'nc-icon nc-layout-11',
            children: [
                {path: 'buttons', title: 'Buttons', ab:'B'},
                {path: 'grid', title: 'Grid System', ab:'GS'},
                {path: 'panels', title: 'Panels', ab:'P'},
                {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
                {path: 'notifications', title: 'Notifications', ab:'N'},
                {path: 'icons', title: 'Icons', ab:'I'},
                {path: 'typography', title: 'Typography', ab:'T'}
            ]
        }
    }

    getTables(){
        return {
            path: '/tables',
            title: 'Tables',
            type: 'sub',
            collapse: 'tables',
            icontype: 'nc-icon nc-single-copy-04',
            children: [
                {path: 'regular', title: 'Regular Tables', ab:'RT'},
                {path: 'extended', title: 'Extended Tables', ab:'ET'},
                {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
            ]
        }
    }

    getRooms(){
        let children = []
        //console.log(this.userPermits[17])
        if(/*this.userPermits[1].some(p => p.create == 1)*/true)
        {
            if(this.userPermits[17].some(p => p.add_new_area == 1 || p.add_room_area == 1 || p.view == 1 || p.edit == 1 || p.pause == 1)){
                children.push({path: 'rooms-area', title: 'Areas de Encamamiento', ab:'AE'});
            }
            if(this.userPermits[17].some(p => p.assign_admission_room == 1)){
                children.push({path: 'rooms-assigment', title: 'Asignación de Habitación', ab:'AH'});
            }
            
            if(this.userPermits[17].some(p => p.patient_list == 1)){
                children.push({path: 'view-hospitalized-patients', title: 'Pacientes Hospitalizados', ab:'PH'});
            }
            
            if(this.userPermits[17].some(p => p.high_medical_discharge == 1)){
                children.push({path: 'medical-release', title: 'Egreso/Alta Médica', ab:'AM'});
            }
        }

        return {
            path: '/rooms',
            title: 'HABITACIONES',
            type: 'sub', 
            collapse: 'rooms',
            icontype: '../../assets/img/icons/charges.png',
            children: children
        }
    }

    getReports(){
        let reports = {
            path: '/reports',
            title: 'REPORTES',
            type: 'link',
            icontype: '../../assets/img/icons/dashboard.svg'
        };
        return reports;
    }
    
    constructor(private permitsService: PermitsService, private router: Router, private cookieService: CookieService){}

    ngOnInit() {
        this.userName = this.cookieService.get(environment.usernameData);
        
        forkJoin([this.permitsService.getUserPermits(this.cookieService.get(environment.idUserData), this.cookieService.get(environment.idData))]).subscribe(
            (res) => {
              this.userPermits = res[0].data;
               //console.log(this.userPermits);
              //Define the modules
                this.menuItems = [];
                this.menuItems.push(this.getDashboard());
                let tmp = this.getAdmin();
                if(tmp.children.length > 0) this.menuItems.push(tmp);
                tmp = this.getPatients();
                if(tmp.children.length > 0) this.menuItems.push(tmp);
                tmp = this.getAdmissions();
                if(tmp.children.length > 0) this.menuItems.push(tmp);
                tmp = this.getInventories(); 
                if(tmp.children.length > 0)this.menuItems.push(tmp);
                tmp = this.getCharges(); 
                if(tmp.children.length > 0) this.menuItems.push(tmp)
                //encamamiento
                tmp = this.getRooms();
                if(tmp.children.length > 0) this.menuItems.push(tmp);
                //this.menuItems.push(this.getReports());
                
                //this.menuItems.push(this.getComponents());
                //this.menuItems.push(this.getForms());
                //this.menuItems.push(this.getTables());
                // console.log('Menu', this.menuItems);
            },
            (err) => {
              console.log(err);
            }
          ) 
        
        //this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    ngAfterViewInit(){
    }
}