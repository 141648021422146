import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { LicenseService } from '../../services/administration/license/license.service'
import { PermitsService } from '../../services/administration/permits/permits.service'
import { UsersService } from '../../services/administration/users/users.service'
import { CookieService } from 'ngx-cookie-service';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ExchangeRate } from '../../models/Exchange_Rate';
import {ChildMessageRenderer} from './child-message-renderer.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.css']
})

export class ExchangeRateComponent implements OnInit {

  exchangeRate: ExchangeRate = { 
    id_exchange_rate: 0,
    id_license: 13,
    id_currency_base: 1,
    exchange_rate: 0.00
  }

  public defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: 100
  }

  public frameworkComponents = {
    childMessageRenderer: ChildMessageRenderer
  };

  public columnDefs;
  public rowDataa;
  idLicenseIndividual;
  edit: boolean = false;
  create: boolean = false;
  dataExchangeRate;

  userPermits: any;
  canEdit: boolean;
  canAdd: boolean;

  repeatValueCreate: boolean = false;
  repeatValueEdit: boolean = false;
  ExchangeRates = [];
  previousData = [];
  valueToEdit = [];
  count:number = 0;

  //Catálogo de moneda base 
  moneda_base = [
    {'Id': 1, 'MonedaBase':'Quetzal Guatemalteco', 'ISO': 'GTQ'},
    {'Id': 2, 'MonedaBase':'Dólar Estadounidense', 'ISO': 'USD'},
    {'Id': 3, 'MonedaBase':'Euro', 'ISO': 'EUR'},
    {'Id': 4, 'MonedaBase':'Yuan Chino', 'ISO': 'CNY'},
    {'Id': 5, 'MonedaBase':'Libra Esterlina', 'ISO' :'GBP'},
    {'Id': 6, 'MonedaBase':'Yen Japonés', 'ISO' : 'JPY'},
    {'Id': 7, 'MonedaBase':'Rupia India', 'ISO' : 'INR'},
    {'Id': 8, 'MonedaBase':'Peso Mexicano', 'ISO' : 'MXN'},
    {'Id': 9, 'MonedaBase':'Lempira Hondureña', 'ISO' : 'HNL'},
    {'Id': 10, 'MonedaBase':'Córdoba Nicaragüense', 'ISO' : 'NIO'},
    {'Id': 11, 'MonedaBase':'Colón Costarricense', 'ISO' : 'CRC'},
    {'Id': 12, 'MonedaBase':'Balboa Panameña', 'ISO' : 'PAB'},
    {'Id': 13, 'MonedaBase':'Real Brasileño', 'ISO' : 'BRL'},
    {'Id': 14, 'MonedaBase':'Dólar Canadiense', 'ISO' : 'CAD'}  
  ]
  
  ngOnInit(): void { 
    forkJoin([this.permitsService.getUserPermits(this.cookieService.get(environment.idUserData), this.cookieService.get(environment.idData))]).subscribe(
      (res) => {
        this.userPermits = res[0].data;
        //console.log("Permisos: ", this.userPermits);
        this.canEdit = (this.userPermits[0].some(p => p.edit_exchangerate == 1)) ? true : false;
        this.canAdd = (this.userPermits[0].some(p => p.add_exchangerate == 1)) ? true : false;
        this.columnDefs = [
          { field: 'Nombre', 
            headerName:'Nombre',
            editable: !this.canEdit?
            function(params:any){
              //console.log(params.node.data)
              return params.node.data.IdExchangeRate?false:true
            }:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams:{
              values:[    
                ' ',
                'Quetzal Guatemalteco',
                'Dólar Estadounidense',
                'Euro',
                'Yuan Chino',
                'Libra Esterlina',
                'Yen Japonés',
                'Rupia India',
                'Peso Mexicano',
                'Lempira Hondureña',
                'Córdoba Nicaragüense',
                'Colón Costarricense',
                'Balboa Panameña',
                'Real Brasileño',
                'Dólar Canadiense'
              ],
            }
          },
          { field: 'Codigo_ISO', headerName:'Código ISO'},
          { field: 'Tasa', headerName:'Tasa de Cambio', 
            editable: !this.canEdit?
            function(params:any){
              return params.node.data.IdExchangeRate?false:true
            }:true, 
            cellStyle: {'text-align': 'right' }},
          { field: 'Delete', headerName: "Acciones", cellRenderer: "childMessageRenderer", colId: "params", editable: false, minWidth: 150 }
        ];
      
      },
      (err) => {
        console.log(err);
      }
    )
    //Obtener id de la licencia 
    this.idLicenseIndividual = this.cookieService.get(environment.licenseData);
    

    this.getData();
  }


  constructor(private cookieService:CookieService, private usersService: UsersService, private permitsService: PermitsService, private licenseService: LicenseService, private route: ActivatedRoute, private router: Router, public ngselect: NgSelectModule, private element: ElementRef, private http: HttpClient){
  }


  getData(){
   // this.count = 0;
    let rowData = [];
    this.licenseService.getExchangeRate(this.idLicenseIndividual).subscribe((response) =>{
      for (let data of response){
        this.count++;
        rowData.push(
          {IdExchangeRate: data.id_exchange_rate, Nombre: data.name_currency_base, Codigo_ISO: data.iso, Tasa: data.exchange_rate}
        )   
      }
      
    this.rowDataa = rowData;
    this.previousData = response;
    });  
  }


  seeData(){
    let rowData = [];
    let index = 0;
    this.count = -1;
    for (let data of this.rowDataa){
      index++;
      this.count++;
      rowData.push(
        {IdExchangeRate: data.IdExchangeRate, Registro: index, Nombre: data.Nombre.toString(), Codigo_ISO: data.Codigo_ISO.toString(), Tasa: data.Tasa.toString(), Id: data.Id}
      )
    }
  
    this.rowDataa = rowData;
  }


  //Se ingresa al método cuando se selecciona el botón guardar, esta opción permite verificar si se esta creando, editando o realizando las dos opciones
  opciones(){
    if(this.edit && !this.create){
      this.editData();
    }else if(this.create && !this.edit){
      this.createData();
    }else if (this.edit && this.create){
      this.createData();
      if(this.edit){
        this.editData();
      }           
    }else{
      Swal.fire({
        title: `Error`,
        text: 'No cuenta con permisos para editar tasa de cambio',
        icon: 'error',
        customClass: {
          confirmButton: "btn btn-info",
        },
        buttonsStyling: false
      })
    }
  }

  
  generateRow(){
    this.create = true;
    this.rowDataa.push({
      Nombre: '', 
      Codigo_ISO: '', 
      Tasa: '0.00',
      Id: 0
    });

    this.seeData();
    this.count++;
    this.ExchangeRates.push({'register': this.count});
  }


  limpiarFilasVacias(){
    //Elimina espacios vacios
    this.rowDataa = this.rowDataa.filter(el => el.Tasa != '' && el.Nombre != '');
  }


  onCellValueChanged( value: any ) {
    //Si existe una modificación en el select este bucle permitirá cambiar su ISO correspondiente automáticamente 
    if(this.canEdit){
      this.moneda_base.forEach(function (data) {
        if(value.data.Nombre == data.MonedaBase){
            value.data.Codigo_ISO = data.ISO;
            value.data.Id = data.Id;
          }
      });
  
      this.seeData();
      //this.consulta(value.data);
      this.searchEditedData(value.data);
    }
  }


  consulta(value){
    debugger
    for(let item of this.previousData){
      if(item.id_currency_base == value.Id && item.id_exchange_rate != value.IdExchangeRate){
        //Existen repetidos
        this.repeatValueEdit = true;
      }else{
        this.repeatValueEdit = false;
      }
    } 
  }


  //Permite verificar si se ha asignado un dato repetido
  findRepeatedValue(value){  

    for(let item of this.previousData){
      //VERIFICAR NO ES LO MISMO EDITAR QUE CREAR
      if(value.data.Nombre == item.name_currency_base){
        this.repeatValueEdit = true;
      }
    } 
  }

  varificarValoresRepetidos(dataToSave){
    let cantidad = dataToSave;
    dataToSave = dataToSave.filter((thing, index, self) => self.findIndex(t => t.id_currency_base === thing.id_currency_base) === index)

    if(cantidad.length != dataToSave.length){
      this.repeatValueCreate = true;
    }else{
      this.repeatValueCreate = false;
    }
  }


  verificarValoresRepetidosAntiguos(dataToSave){
    this.previousData.filter((d) => {
      dataToSave.filter((s) => {
            if (d.id_currency_base == s.id_currency_base) {
              this.repeatValueCreate = true;
            }
        })
    })   
  }


  //Permite verificar si se ha editado algun registro
  searchEditedData(value){
    let index = 0;
    let ingreso = true;

    //Verifica si existen datos modificados en "Datos a editar", si cumple la condición cambia los valores a los actuales, por el contrario los registra
    for(let item of this.valueToEdit){
      if(value.IdExchangeRate == item.id_exchange_rate){
        ingreso = false
        this.valueToEdit[index] = ({'id_exchange_rate': item.id_exchange_rate,'id_license': this.idLicenseIndividual, 'id_currency_base': value.Id, 'exchange_rate': value.Tasa});
        break;
      }else{
        ingreso = true;        
      }
      index++;
    }

    if(ingreso == true){
      for(let item of this.previousData){
        if(value.IdExchangeRate == item.id_exchange_rate){
          this.valueToEdit.push({'id_exchange_rate': item.id_exchange_rate,'id_license': this.idLicenseIndividual, 'id_currency_base': value.Id, 'exchange_rate': value.Tasa});     
        }
      }
    }

    if(this.valueToEdit.length > 0){
      this.edit = true;
    }
  }


  editData(){
    let index = 0;
    let dataSize = this.valueToEdit.length;

    if(this.repeatValueEdit){
      Swal.fire({
        title: `Error`,
        text: 'La operación ha fallado, existe un nombre de moneda repetido',
        icon: 'error',
        customClass: {
          confirmButton: "btn btn-info",
        },
        buttonsStyling: false
      })

    }else{
      for (let rightt of this.valueToEdit) {
        this.licenseService.updateExchangeRate(this.valueToEdit[index].id_exchange_rate, this.valueToEdit[index])
        .subscribe(
          res => {
  
              if(dataSize > 1){
                Swal.fire({
                  title: '¡Exito!',
                  text: 'Los tipos de cambio fueron editados correctamente',
                  icon: 'success',
                  customClass:{
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false
                })
                this.ngOnInit();
              }
              else{
                Swal.fire({
                  title: '¡Exito!',
                  text: 'El tipo de cambio fue modificado exitosamente',
                  icon: 'success',
                  customClass:{
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false
                })
                this.ngOnInit();
              }
          },
          err =>{
            Swal.fire({
              title: `Error`,
              text: 'No se pudo actualizar el tipo de cambio',
              icon: 'error',
              customClass: {
                confirmButton: "btn btn-info",
              },
              buttonsStyling: false
            })
          } 
        )
        index++;
      }
    }

    this.valueToEdit = [];
    this.repeatValueEdit = false;
    this.edit = false;
    this.ngOnInit();
  }


  DeleteData(id){
    if(this.canEdit){

      Swal.fire({
        title: '¿Desea eliminar este Tipo de Cambio?',
        text: "Esta acción es permanente.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
        buttonsStyling: false
      }).then((result) => {
      
        if (result.value) {        
          this.licenseService.deleteExchangeRate(id).subscribe(
            res => {
              Swal.fire({
                title: 'Tipo de Cambio eliminado',
                text: 'Has eliminado correctamente el Tipo de Cambio.',
                icon: 'success',
                customClass: {
                  confirmButton: "btn btn-success",
                },
                  buttonsStyling: false
              })
              this.ngOnInit();
            },
              err => {
                console.error(err)
              }
            )
          }
        })   
    }else{
      Swal.fire({
        title: `Error`,
        text: 'No cuenta con permisos para editar tasa de cambio',
        icon: 'error',
        customClass: {
          confirmButton: "btn btn-info",
        },
        buttonsStyling: false
      })
    }
  }

  createData(){
    this.limpiarFilasVacias();
    let dataToSave = [];
    let index = 0;

    //Recorres ambos arreglos para obtener la diferencia entre los datos cargados y los actuales
    this.rowDataa.filter((d) => {
      this.ExchangeRates.filter((s) => {
            if (d.Registro == s.register) {
              dataToSave.push({'id_license': this.idLicenseIndividual, 'id_currency_base': d.Id, 'exchange_rate': d.Tasa})
            }
        })
    });

    this.varificarValoresRepetidos(dataToSave);
    this.verificarValoresRepetidosAntiguos(dataToSave)

  
    if(this.repeatValueCreate || this.repeatValueEdit){
      Swal.fire({
        title: `Error`,
        text: 'La operación ha fallado, existe un nombre de moneda repetido',
        icon: 'error',
        customClass: {
          confirmButton: "btn btn-info",
        },
        buttonsStyling: false
      })
 
      this.ExchangeRates = [];
      this.repeatValueCreate = false;
      this.repeatValueEdit = false;
      this.edit = false;
      this.create = false;
      this.ngOnInit();
    }

    else if(this.repeatValueCreate){
      Swal.fire({
        title: `Error`,
        text: 'La operación ha fallado, existe un nombre de moneda repetido',
        icon: 'error',
        customClass: {
          confirmButton: "btn btn-info",
        },
        buttonsStyling: false
      })
 
      this.ExchangeRates = [];
      this.repeatValueCreate = false;
      this.edit = false;
      this.create = false;
      this.ngOnInit();
    }
    else{
      for (let data of dataToSave) {    
        this.licenseService.saveExchangeRate(dataToSave[index])
        .subscribe(
          res => {
            if (res["success"]) {

              if(dataToSave.length > 1){
                Swal.fire({
                  title: '¡Exito!',
                  text: 'Los tipos de cambio fueron agregados correctamente',
                  icon: 'success',
                  customClass:{
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false
                  
                })
                this.ngOnInit();
              }
              else if (dataToSave.length == 1){
                Swal.fire({
                  title: '¡Exito!',
                  text: res['message'],
                  icon: 'success',
                  customClass:{
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false
                })
              }
              this.ngOnInit();
            }
            else {
              Swal.fire({
                title: `Error`,
                text: res['message'],
                icon: 'error',
                customClass: {
                  confirmButton: "btn btn-info",
                },
                buttonsStyling: false
              })
            } 
          },
          err => 
          {
            console.error(err)
          }
        );
        index++;
      } 
    }
    this.ExchangeRates = [];
    this.repeatValueCreate= false;
  //<<  this.edit = false;
    this.create = false;
    this.ngOnInit();
  }

}