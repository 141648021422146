import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermitsService {

  constructor(private http: HttpClient) { }

  //Permit
  //Get permit information by name
  public getPermitByName(name): Observable<any>{
    let options =  new HttpParams();
    options = options.append('name', name);
    return this.http.get(environment.getPermitByName, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  public getGroupModules(): Observable<any>{
    return this.http.post(environment.getModules, {'id_role':''}).pipe(
      map(res => {
        ////console.log("respuesta de servicio modulos", res['data']);
        return res['data'];
      }),
      catchError(err => of(err))
    )
  }

  //Permit Status
  //Get permits by user
  public getUserPermits(userId, license): Observable<any>{
    let options =  new HttpParams();
    options = options.append('userId', userId);
    options = options.append('license', license);
    return this.http.get(environment.getPermitsByUser, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Get all the licenses that a user has assigned
  public getLicensesByUser(userId, isAdmin, corpLicense): Observable<any>{
    let options =  new HttpParams();
    options = options.append('userId', userId);
    options = options.append('isAdmin', isAdmin);
    options = options.append('corpLicense', corpLicense);
    return this.http.get(environment.getLicencesByUser, {params: options }).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Update user permits
  public updateUserPermits(body): Observable<any>{
    return this.http.post(environment.updatePermits, body).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
}
